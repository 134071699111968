import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { css, mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button, DrawFocus, SafeHtml } from '@thg-commerce/gravity-elements'
import { TextStyling } from '@thg-commerce/gravity-patterns'
import { Picture } from '@thg-commerce/gravity-system'
import {
  BreakpointArray,
  mediaQueryRenderer,
  padding,
  SpacingBox,
  TextEntry,
} from '@thg-commerce/gravity-theme'

import { PaddingInterface } from '../theme'

export const Container = styled.div<{
  containerPadding: PaddingInterface
}>`
  max-width: ${(props) => props.theme.site.siteWidth};
  margin: 0 auto;
  width: 100%;
  overflow-x: auto;

  ${(props) => padding(props.containerPadding)}
`

export const EditorialItems = styled.div<{
  wraps: BreakpointArray<boolean>
  widths: BreakpointArray<string>
  gap: number
}>`
  display: flex;
  justify-content: center;
  gap: ${(props) => spacing(props.gap)};
  flex-direction: column;
  margin-top: ${spacing(2)};

  ${(props) => mediaQueryRenderer(props.widths, (width) => `width: ${width};`)}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    flex-direction: row;
  }

  ${(props) =>
    mediaQueryRenderer(
      props.wraps,
      (wrap) => `flex-wrap: ${wrap ? 'wrap' : 'nowrap'};`,
    )}
`

export const StyledAnchor = styled.a<{
  directions: BreakpointArray<'row' | 'column'>
  withFocus?: boolean
  anchorWidth?: string
  enableEditorialItemFocusOutline?: boolean
}>`
  height: ${(props) =>
    props.theme.widget.editorialItem.height
      ? props.theme.widget.editorialItem.height
      : 'auto'};
  text-decoration: none;
  width: ${(props) => props.anchorWidth || 'auto'};
  outline: none;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid transparent;

  ${(props) =>
    mediaQueryRenderer(
      props.directions,
      (direction) => `flex-direction: ${direction};`,
    )}

  &:focus {
    border: 2px solid
      ${(props) =>
        props.enableEditorialItemFocusOutline
          ? props.theme.colors.palette.brand.base
          : 'transparent'};
    ${(props) => props.withFocus && DrawFocus()}
  }
`

export const HighlightText = styled.p<{
  textColour?: string
  horizontalAlignment?: HorizontalAlignment
  subtitle?: string
}>`
  ${(props) =>
    Text(
      props.theme.widget.editorialItem.subtitle.textStyle.entry || 'small',
      'alternate',
    )};
  margin-top: ${spacing(2)};

  ${(props) =>
    props.horizontalAlignment && `text-align: ${props.horizontalAlignment};`}

  ${(props) =>
    props.textColour &&
    `color: ${
      props.textColour === 'light'
        ? props.theme.colors.palette.greys.light
        : props.theme.colors.palette.greys.darker
    }`};
`

export const EditorialTitle = styled.h3<{
  textColour?: string
  horizontalAlignment?: HorizontalAlignment
  font?: TextStyling
}>`
  ${(props) =>
    Text(props.font?.entry || 'medium1', props.font?.style || 'alternate')};
  margin-top: ${spacing(2)};
  word-break: break-word;
  width: 100%;

  ${(props) =>
    props.horizontalAlignment && `text-align: ${props.horizontalAlignment};`}

  ${(props) =>
    props.textColour &&
    `color: ${
      props.textColour === 'light'
        ? props.theme.colors.palette.greys.light
        : props.theme.colors.palette.greys.darker
    }`}
`

export const EditorialContent = styled(SafeHtml)<{
  horizontalAlignment?: HorizontalAlignment
}>`
  ${(props) =>
    Text(
      props.theme.widget.editorialItem.twoItemEditorial.contentStyle?.entry ||
        'bodyText',
      props.theme.widget.editorialItem.twoItemEditorial.contentStyle?.style ||
        'default',
    )}
  margin-top: ${spacing(1)};

  ${(props) =>
    props.horizontalAlignment && `text-align: ${props.horizontalAlignment};`}
`

export const PictureContainer = styled.div<{
  isOnHover: boolean
}>`
  width: 100%;
  flex-basis: calc(50% - 16px);

  ${(props) =>
    props.isOnHover &&
    `&:hover {
        ${StyledPicture} {
          display: none;
        }
        ${HoverPicture} {
            display: inline;
          }
        }
    `};
`

export const HoverPicture = styled(Picture)`
  display: none;
`

export const StyledPicture = styled(Picture)`
  width: 100%;
  height: auto;
`

export const EditorialLinkContainer = styled.div<{
  alignment?: HorizontalAlignment
}>`
  ${(props) =>
    props.alignment &&
    css`
      text-align: ${props.alignment};
      width: 100%;
      display: ${(props) =>
        props.theme.widget.editorialItem.linkContainer.display};
      align-items: ${(props) =>
        props.theme.widget.editorialItem.linkContainer.alignItems};
      height: ${(props) =>
        props.theme.widget.editorialItem.linkContainer.height};
      justify-content: ${(props) =>
        props.theme.widget.editorialItem.linkContainer.justifyContent};
}
    `};
`

export const EditorialLink = styled(Button)<{
  mobileCTAWidth?: string
  ctaPadding?: SpacingBox
}>`
  ${Text('bodyText', 'default')};
  ${(props) =>
    props.mobileCTAWidth
      ? css`
          width: ${props.mobileCTAWidth};
          ${mq(props.theme.breakpointUtils.map, 'sm')} {
            width: fit-content;
          }
        `
      : 'width: fit-content;'};
  text-decoration: none;
  margin-top: ${spacing(2)};
  text-transform: none;

  &:hover,
  &:focus {
    ${Text('bodyText', 'default')};
    text-decoration: none;
  }

  ${(props) =>
    props.ctaPadding &&
    `
    a,
    a:active,
    a:hover,
    button,
    button:active,
    button:hover {
      padding: ${spacing(props.ctaPadding.top)} ${spacing(
      props.ctaPadding.right,
    )} ${spacing(props.ctaPadding.bottom)} ${spacing(props.ctaPadding.left)};
    }

    a:focus,
    button:focus {
      padding: calc(${spacing(props.ctaPadding.top)} - 2px) calc(${spacing(
      props.ctaPadding.right,
    )} - 2px) calc(${spacing(props.ctaPadding.bottom)} - 2px) calc(${spacing(
      props.ctaPadding.left,
    )} - 2px);
    }
  `}
`

export const Title = styled.h2<{
  textEntry?: TextEntry
}>`
  ${(props) =>
    Text(
      props.textEntry || props.theme.widget.editorialItem.title.font.entry,
      props.theme.widget.editorialItem.title.font.style,
    )}
  text-align: center;
`

export const Subtitle = styled.p`
  ${Text('bodyText', 'default')};
  text-align: center;
  margin-top: ${spacing(1)};
`

export const EditorialItemContainer = styled.div<{
  hasBorder?: boolean
  flexBasis?: BreakpointArray<string>
  order?: BreakpointArray<number>
}>`
  ${(props) =>
    props.hasBorder &&
    `border: solid 1px ${
      props.theme.widget.editorialItem.andromedaEditorial.outline.enabled
        ? props.theme.colors.palette.greys.grey
        : 'transparent'
    };`}

  ${(props) =>
    props.flexBasis &&
    mediaQueryRenderer(
      props.flexBasis,
      (flexBasis) => `flex-basis: ${flexBasis}`,
    )}

  ${(props) =>
    props.order &&
    mediaQueryRenderer(props.order, (order) => `order: ${order}`)}
`

export const StyledContainer = styled.div<{
  horizontalAlignment?: HorizontalAlignment
  contentGutterPadding: PaddingInterface
}>`
  flex-basis: 50%;
  width: 100%;

  ${(props) =>
    props.horizontalAlignment &&
    `display: flex; 
    flex-direction: column;
    align-items: ${
      props.horizontalAlignment === 'center'
        ? 'center'
        : props.horizontalAlignment === 'left'
        ? 'flex-start'
        : 'flex-end'
    };
  `};

  ${(props) => padding(props.contentGutterPadding)}
`
