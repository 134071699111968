export enum WidgetTypes {
  AccordionWidget = 'AccordionWidget',
  AccordionWidgetContainer = 'AccordionWidgetContainer',
  BMICalculator = 'BMICalculator',
  BMICalculatorV2 = 'BMICalculatorV2',
  BrandsPageWidget = 'BrandsPageWidget',
  BuildYourOwnBundleProductList = 'BuildYourOwnBundleProductList',
  DeliveryInfoWidget = 'DeliveryInfoWidget',
  DeliveryInformationWidget = 'DeliveryInformationWidget',
  DynamicReferralWidget = 'DynamicReferralWidget',
  GlobalReferral3StepGuide = 'GlobalReferral3StepGuide',
  Easiware = 'Easiware',
  EditorialWidget = 'EditorialWidget',
  EmailReEngagementModal = 'EmailReEngagementModal',
  FastTrackBanner = 'FastTrackBanner',
  FoundationFinderLandingPageWidget = 'FoundationFinderLandingPageWidget',
  GlobalAboutUsVideo = 'GlobalAboutUsVideo',
  GlobalAccreditationIcon = 'GlobalAccreditationIcon',
  GlobalAccreditationIconCollection = 'GlobalAccreditationIconCollection',
  GlobalBrandLogos = 'GlobalBrandLogos',
  GlobalBuyingRightNow = 'GlobalBuyingRightNow',
  GlobalBuyingRightNowManualProductURL = 'GlobalBuyingRightNowManualProductURL',
  GlobalCardScrollerCard = 'GlobalCardScrollerCard',
  GlobalCardScrollerSet = 'GlobalCardScrollerSet',
  GlobalDispatchAndDateCountdownWidget = 'GlobalDispatchAndDateCountdownWidget',
  GlobalEditorialWithFeature = 'GlobalEditorialWithFeature',
  GlobalFooterAccreditationIcons = 'GlobalFooterAccreditationIcons',
  GlobalFooterContactUs = 'GlobalFooterContactUs',
  GlobalFourBestSellers = 'GlobalFourBestSellers',
  GlobalFourButtonLink = 'GlobalFourButtonLink',
  GlobalFourItemEditorial = 'GlobalFourItemEditorial',
  GlobalGeneralImageBanner = 'GlobalGeneralImageBanner',
  GlobalHelpCentreCollection = 'GlobalHelpCentreCollection',
  GlobalHeroCTABanner = 'GlobalHeroCTABanner',
  GlobalImageCard = 'GlobalImageCard',
  GlobalImageCardSet = 'GlobalImageCardSet',
  GlobalMultiButton = 'GlobalMultiButton',
  GlobalPrimaryBanner = 'GlobalPrimaryBanner',
  GlobalPrimaryBannerWithList = 'GlobalPrimaryBannerWithList',
  GlobalPrimaryBannerWithTextOverlay = 'GlobalPrimaryBannerWithTextOverlay',
  GlobalProductCardScroller = 'GlobalProductCardScroller',
  GlobalScalableLogos = 'GlobalScalableLogos',
  GlobalSectionPeek = 'GlobalSectionPeek',
  GlobalSetAndromeda = 'GlobalSetAndromeda',
  GlobalSimpleTextCTAWidget = 'GlobalSimpleTextCTAWidget',
  GlobalSixItemCategories = 'GlobalSixItemCategories',
  GlobalSocialIcon = 'GlobalSocialIcon',
  GlobalSocialIconCollection = 'GlobalSocialIconCollection',
  GlobalSocialIconCollectionv2 = 'GlobalSocialIconCollectionv2',
  GlobalSocialIconv2 = 'GlobalSocialIconv2',
  GlobalStripBanner = 'GlobalStripBanner',
  GlobalSubscriptionOptions = 'GlobalSubscriptionOptions',
  GlobalTabbedWidgetSet = 'GlobalTabbedWidgetSet',
  GlobalThreeItemEditorialSubtitleBG = 'GlobalThreeItemEditorialSubtitleBG',
  GlobalThreeItemEditorial = 'GlobalThreeItemEditorial',
  GlobalTransformationSlider = 'GlobalTransformationSlider',
  GlobalTrendingHashtagBlock = 'GlobalTrendingHashtagBlock',
  GlobalTrendingHashtagCollection = 'GlobalTrendingHashtagCollection',
  GlobalTwoBestSellers = 'GlobalTwoBestSellers',
  GlobalTwoItemEditorial = 'GlobalTwoItemEditorial',
  GlobalTwoItemImageTextBlock = 'GlobalTwoItemImageTextBlock',
  GlobalTwoItemImageTextCTA = 'GlobalTwoItemImageTextCTA',
  GlobalTwoItemImageTextCTA3070 = 'GlobalTwoItemImageTextCTA3070',
  GlobalUGCCarousel = 'GlobalUGCCarousel',
  GlobalVideoGallery = 'GlobalVideoGallery',
  GlobalVideoHeroBannerWidget = 'GlobalVideoHeroBannerWidget',
  GlobalVideoTextImageCTA = 'GlobalVideoTextImageCTA',
  GlobalWaitListSignUpFormResponses = 'GlobalWaitListSignUpFormResponses',
  GlobalWaitListSignUpWidget = 'GlobalWaitListSignUpWidget',
  GlobalWidgetSirius = 'GlobalWidgetSirius',
  GlobalWidgetVega = 'GlobalWidgetVega',
  GlobalYoutubeGalleryItem = 'GlobalYoutubeGalleryItem',
  GlobalYoutubeGallerySet = 'GlobalYoutubeGallerySet',
  LoyaltyHubBirthdayGift = 'LoyaltyHubBirthdayGift',
  LoyaltyHubTier = 'LoyaltyHubTier',
  LoyaltyRewardTier = 'LoyaltyRewardTier',
  LoyaltyRewardTiers = 'LoyaltyRewardTiers',
  MailingList = 'MailingList',
  MultipleCTABanner = 'MultipleCTABanner',
  ProductListWidget = 'ProductListWidget',
  PropositionBar = 'propositionBar',
  ProvenanceBrandHeader = 'provenanceBrandHeader',
  ResponsiveBuildYourOwnBundle = 'ResponsiveBuildYourOwnBundle',
  ResponsiveBuildYourOwnBundleProducts = 'ResponsiveBuildYourOwnBundleProducts',
  ResponsiveComparisonTable = 'ResponsiveComparisonTable',
  ResponsiveComparisonTableContainer = 'ResponsiveComparisonTableContainer',
  ResponsiveProductBlockCarousel = 'ResponsiveProductBlockCarousel',
  ResponsiveProductTabs = 'ResponsiveProductTabs',
  ResponsiveSetSalon = 'ResponsiveSetSalon',
  ResponsiveSetSalonWithSlides = 'ResponsiveSetSalonWithSlides',
  ResponsiveSliderSet = 'ResponsiveSliderSet',
  ResponsiveSuccessStoriesWidgetContainer = 'ResponsiveSuccessStoriesWidgetContainer',
  ResponsiveSuccessStoryWidget = 'ResponsiveSuccessStoryWidget',
  ResponsiveTwoItemComparisonContainer = 'ResponsiveTwoItemComparisonContainer',
  ResponsiveTwoItemComparisonRow = 'ResponsiveTwoItemComparisonRow',
  ResponsiveUSPBar = 'ResponsiveUSPBar',
  SeoProductCategory = 'SeoProductCategory',
  SeoProductCategorySet = 'SeoProductCategorySet',
  ShopTheLookHotSpot = 'ShopTheLookHotSpot',
  TopBrandsWidget = 'TopBrandsWidget',
  TopProductCategory = 'TopProductCategory',
  TopProductCategorySet = 'TopProductCategorySet',
  TrustPilotWidget = 'TrustPilotWidget',
  TrustPilotCarouselWidget = 'TrustPilotCarouselWidget',
  VoucherCodesWidget = 'VoucherCodesWidget',
  asymmetricGrid = 'asymmetricGrid',
  buildyourownbundle = 'buildyourownbundle',
  categoryItemCard = 'categoryItemCard',
  coverageCalculator = 'coverageCalculator',
  eightLinkButtonWidget = 'eightLinkButtonWidget',
  imageSelectCard = 'imageSelectCard',
  imageSelectSlider = 'imageSelectSlider',
  improvedSearchBestSellers = 'improvedSearchBestSellers',
  kitBuilder = 'kitBuilder',
  loyaltyRewardsList = 'loyaltyRewardsList',
  loyaltyWelcomeMessage = 'loyaltyWelcomeMessage',
  multiCategoryCardSet = 'multiCategoryCardSet',
  parcelLabWidget = 'parcelLabWidget',
  promoproductslider = 'promoproductslider',
  revieveWidget = 'revieveWidget',
  shopTheLook = 'shopTheLook',
  simpleCTA = 'simpleCTA',
  simpleTextWidgetSet = 'simpleTextWidgetSet',
  simpleTitleWidget = 'simpleTitleWidget',
  simpletextwidget = 'simpletextwidget',
  simpletextwidgetwithh1 = 'simpletextwidgetwithh1',
  sponsoredProducts = 'sponsoredProducts',
  sponsoredProductsNew = 'sponsoredProductsNew',
  subscribeAndSaveInformationModal = 'subscribeAndSaveInformationModal',
  swatchSelectSlider = 'swatchSelectSlider',
  swatchSelectSliderSet = 'swatchSelectSliderSet',
  trustReinforcementContainer = 'trustReinforcementContainer',
  trustReinforcementSection = 'trustReinforcementSection',
  storyStream = 'storyStreamWidget',
  CriteoSponsoredBannerAds = 'CriteoSponsoredBannerAds',
  sponsoredProductsCriteoHomepage = 'sponsoredProductsCriteoHomepage',
  qubitHomePageRecs = 'qubitHomePageRecs',
}
